/*eslint-disable*/
import React from "react";

// reactstrap components
import { Container, Row, Card,CardBody,CardTitle } from "reactstrap";
// core components

function PresentationHeader() {
  return (
    <>
    <Container fluid>
      <Row>
          <Card
            className="card-fashion page-header-image"
            style={{
              backgroundSize: "100% 100%",
              backgroundImage:
                "url(" + require("assets/img/notarias/bg0.jpg") + ")",
              height: "650px"
            }}
          >
            <CardBody>
              <CardTitle className="text-left" tag="div">
              <div className="rellax-text-container">
                <h1 className="h1-seo" data-rellax-speed="-1" style={{marginTop: "-400px"}}>
                  Notaría 27
                </h1>
                <div className="pro">Bogotá</div>
                <h2 className="title" data-rellax-speed="-1">
                SERVICIOS NOTARIALES
                </h2>
              </div>
              </CardTitle>
            </CardBody>
          </Card>
      </Row>
     </Container>
    </>
  );
}

export default PresentationHeader;
