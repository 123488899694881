import React from "react";
import Iframe from "react-iframe"
import { useSelector} from "react-redux";
import Axios from "axios";

// reactstrap components
import {
  Button,
  Alert,
  FormGroup,
  Form,
  Input,

  InputGroupText,
  InputGroup,
  Label,
  Card,
  CardBody,
  Container,
  Row,
  Col,
} from "reactstrap";

// core components

function Contacto() {

  const [nameFocus, setNameFocus] = React.useState(false);
  const [emailFocus, setEmailFocus] = React.useState(false);

  const [nombres, setNombres] = React.useState("");
  const [correo, setCorreo] = React.useState("");
  const [mensaje, setMensaje] = React.useState("");
  const [checked, setChecked] = React.useState(false);

  const [errorEmail, setErrorEmail] = React.useState({mostrar:false, tipo:'danger', message:''});

  const notaria = useSelector((state) => state.notaria.notaria);

  const enviarCorreo = async(e) => {
    e.preventDefault()
    
     
    if(checked && nombres !==''&& correo !=='' && mensaje !==''){
      try{
          let config = {
            method: 'POST',
            mode: 'cors',
            headers: {"Access-Control-Allow-Origin": true,Authorization: "Bearer "+ process.env.REACT_APP_TOKEN_API},
            }
            let data = {
              'remitente_name': nombres, 
              'remitente_email': correo, 
              'descripcion':mensaje,
              'destinatario': notaria.email_contactenos            
            }            
          const resultado = await Axios.post(process.env.REACT_APP_UrlApi+'MailContact',data,config)
          console.log(resultado)
          if(resultado.status === 200){
            setNombres('')
            setCorreo('')
            setMensaje('')
            setErrorEmail({mostrar:true, tipo:'success', message:'Correo Enviado, pronto le daremos una respuesta'})       
          }else{
            setErrorEmail({mostrar:true, tipo:'danger', message:'Sucedió un error, intentarlo mas tarde'})
          }
      }
      catch(eroror){
        setErrorEmail({mostrar:true, tipo:'danger', message:'Sucedió un error, intentarlo mas tarde'})
      }
    }else{
      setErrorEmail({mostrar:true, tipo:'danger', message:'Campos vacíos'})
      setTimeout(() => {
        setErrorEmail({mostrar:false, tipo:'danger', message:''})
      }, 3000)
    }
   
  }  

  return (
    <>
      <div className="section" id="contacto">
        <Container>
          <Row>
            <Col md="12">
              <h2 className="title text-center">Información de contacto</h2>
                <h5 className="description text-justify" style={{color: "Black"}}>
                  <b>Necesita más información? Visite nuestras oficinas o comuníquese con nuestras
                     líneas de atención al cliente para recibir información adicional.</b>
                </h5>
            </Col>
          </Row>
          <Row>
            <Col md="5">
              <Card style={{borderRadius: "15px"}}>
                <CardBody>
                  <h4 className="info-title text-center">Contáctenos por Correo electrónico</h4>
                  <Form id="contact-form" method="post" role="form">
                    <label>Nombre</label>
                    <InputGroup
                      className={nameFocus ? "input-group-focus" : ""}
                    >
                      <InputGroupText>
                        <i className="now-ui-icons users_circle-08"></i>
                      </InputGroupText>
                      <Input
                        placeholder="Nombre completo"
                        type="text"
                        onFocus={() => setNameFocus(true)}
                        onBlur={() => setNameFocus(false)}
                        onChange={(e) => setNombres(e.target.value)}
                      ></Input>
                    </InputGroup>
                    <label>Correo electrónico</label>
                    <InputGroup
                      className={emailFocus ? "input-group-focus" : ""}
                    >
                      <InputGroupText>
                        <i className="now-ui-icons ui-1_email-85"></i>
                      </InputGroupText>
                      <Input
                        placeholder="example@email.com"
                        type="email"
                        onFocus={() => setEmailFocus(true)}
                        onBlur={() => setEmailFocus(false)}
                        onChange={(e) => setCorreo(e.target.value)}
                      ></Input>
                    </InputGroup>
                    <FormGroup>
                      <label> Mensaje</label>
                      <Input
                        id="message"
                        name="message"
                        rows="6"
                        type="textarea"
                        onChange={(e) => setMensaje(e.target.value)}
                      ></Input>
                    </FormGroup>
                    <FormGroup check>
                      <Label check>
                        <Input 
                          type="checkbox"
                          onChange={(e) => setChecked(true)}
                        ></Input>
                        <span className="form-check-sign"></span>
                          No soy un robot 
                      </Label>
                    </FormGroup>
                    <div className="submit text-center">
                      <Button
                        color="info"
                        className="btn-raised btn-round"
                        defaultValue="Enviar"
                        onClick={(e) => enviarCorreo(e)}
                      >
                        <b style={{fontSize: "16px"}}>Enviar Mensaje</b>
                      </Button>
                    </div>
                  </Form>
                  {errorEmail.mostrar &&
                    <Row className="justify-content-center" id="error">
                      <Col ms="6">
                        <Alert color={errorEmail.tipo}>
                          <b style={{fontSize: "16px"}}>{errorEmail.message}</b>
                        </Alert>
                      </Col>
                    </Row>
                  }
                </CardBody>
              </Card>
            </Col>
            <Col md="5" className="justify-content-center">
              <Card style={{borderRadius: "15px"}}>
                <CardBody>
                  <div className="info-horizontal">
                    <div className="icon icon-info">
                      <i className="now-ui-icons location_pin"></i>
                    </div>
                    <div className="description">
                      <h4 className="info-title">Estamos ubicados</h4>
                      <p style={{color:"Black"}}><b>
                        Carrera 15 N° 75-24 Bogotá, Cundinamarca<br></br>
                        Lunes a Viernes <br></br>
                        8:00 am - 1:00 pm y 2:00 pm - 5:00 pm <br></br>
                        Sábados:<br></br>
                        9:00 am - 1:00 pm  <br></br>
                        <br></br>
                        Marzo 15
                        <br></br>
                        Abril 19
                        <br></br>
                        Mayo 24
                        <br></br>
                        Junio 28
                        <br></br>
                        Agosto 2
                        <br></br>
                        Septiembre 6
                        <br></br>
                        Octubre 11
                        <br></br>
                        Noviembre 15
                        <br></br>
                        Diciembre 20
                        <br></br>
                        Info@notaria27bogota.com <br></br>
                        </b></p>
                    </div>
                  </div>
                </CardBody>
              </Card>
              <Row>
                <Col ms="7">
                  <Card style={{borderRadius: "15px"}}>
                    <CardBody>
                      <div className="info-horizontal">
                        <div className="icon icon-info">
                          <i className="now-ui-icons tech_mobile"></i>
                        </div>
                        <div className="description">
                          <h4 className="info-title">Teléfonos</h4>
                          <p style={{color:"Black"}}><b>
                            27 6017438826 <br></br>
                          </b></p>
                        </div>
                      </div>
                    </CardBody>
                  </Card>
                </Col>
              </Row>
            </Col>
            <Col md="2">
              <Card style={{borderRadius: "15px"}}>
                <CardBody>
                  <div className="description">
                    <h4 className="info-title">Rutas</h4>
                    <center>
                      <a href={"https://www.waze.com/livemap/directions/colombia/valle-del-cauca/cali/notaria-21-cali?place=ChIJu-hmrnuhMI4R-rCnFR-MsPY&utm_expid=.K6QI8s_pTz6FfRdYRPpI3A.0&utm_referrer="}>  
                        <img src={require("assets/img/notarias/waze.png")} alt="Logo de la aplicación de Waze" width="50%" style={{textDecoration:"none"}}/>
                      </a>
                    </center>
                    <center>
                      <a  href={"https://www.google.com/maps/dir/?api=1&destination=Notaria+21+de+Santiago+de+Cali,+Cra.+100+%23%2311-60+Local+234B,+Cali,+Valle+del+Cauca"}>
                        <img src={require("assets/img/notarias/google.png")} alt="Logo de la aplicación de Google" width="50%" style={{textDecoration:"none"}}/>
                      </a>
                    </center>
                  </div>
                </CardBody>
              </Card>
              <Card style={{borderRadius: "15px"}}>
                <CardBody>
                  <div className="description">
                    <h4 className="info-title">Atención al público:</h4>
                    <center>
                      <a href="/peticiones" className="btn btn-info" aria-disabled="true" role="button">
                        <b style={{fontSize: "18px"}}> PQRS</b>
                      </a>
                    </center>
                  </div>
                </CardBody>
              </Card>
            </Col>
          </Row>
          <Row>
            <Col md="6">
              <Card style={{borderRadius: "15px"}}>
                <CardBody>
                  <br></br>
                  <h4 className="info-title" style={{color:"#2ca8ff"}}><b>Correo electrónico Institucional:</b></h4>
                  <h4 className="info-title">notaria27bogota@ucnc.com.co</h4>
                  <h4 className="info-title" style={{color:"#2ca8ff"}}><b>Correo Notificaciones Judiciales:</b></h4>
                  <h4 className="info-title">notificaciones@notaria27bogota.com</h4>
                </CardBody>
              </Card>
            </Col>
            <Col md="6">
              <Card style={{borderRadius: "15px"}}>
                <CardBody>
                  <br></br>
                  <h4 className="info-title" style={{color:"#2ca8ff"}}><b>Correo físico:</b></h4>
                  <h4 className="info-title">Carrera 15 N° 75-24 Bogotá, Cundinamarca</h4>
                </CardBody>
              </Card>
            </Col>
          </Row>
          <Row style={{marginTop:"20px"}}>
            <Col md="12">
              <Card style={{borderRadius: "15px"}}>
                <CardBody>
                  <Iframe url="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d15906.470326939343!2d-74.059441!3d4.662083!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x0%3A0x46323f2dd2cdcb6d!2sNotar%C3%ADa%2027!5e0!3m2!1ses-419!2sco!4v1593087768483!5m2!1ses-419!2sco" 
                    width = "100%"
                    frameBorder = "0" 
                    height="40%" 
                    className="text-center"
                    frameborder="0"
                    allowfullscreen="" 
                    aria-hidden="false" 
                    tabindex="0">
                  </Iframe>
                </CardBody>
              </Card>
            </Col>
          </Row>
          <Card>
            <b>Entidades, gremiaciones y asociaciones</b>
            <CardBody>
              <Row>
                <Col Col md="2" sm="2">
                  <a href="http://www.minjusticia.gov.co/" target="_blank" rel="noopener noreferrer" style={{textDecorationLine: "none"}}>
                    <img
                      alt="Logo de Ministero de Justicia y Derecho de Colombia"
                      src={require("assets/img/notarias/justicia.png")}
                    ></img>
                  </a>
                </Col>
                <Col Col md="3" sm="1">
                  <a href="https://www.supernotariado.gov.co/" target="_blank" rel="noopener noreferrer" style={{textDecorationLine: "none"}}>
                    <img
                        alt="Logo de SNR y Registro"
                        src={require("assets/img/notarias/LOGO_SNR.png")}
                    ></img>
                  </a>
                </Col>
                <Col Col md="1" sm="1">
                  <a href="https://www.procuraduria.gov.co/portal/" target="_blank" rel="noopener noreferrer" style={{textDecorationLine: "none"}}>
                    <img
                        alt="Logo Procuraduria General de la Nación"
                        src={require("assets/img/notarias/procuraduria.jpg")}
                    ></img>
                  </a>
                </Col>
                <Col Col md="2" sm="2">
                  <a href="https://www.suin-juriscol.gov.co/" target="_blank" rel="noopener noreferrer" style={{textDecorationLine: "none"}}>
                    <img
                        alt="Logo SUIN Juriscol"
                        src={require("assets/img/notarias/suin.png")}
                    ></img>
                  </a>
                </Col>
                <Col Col md="2" sm="2">
                  <a href="https://www.vur.gov.co/" target="_blank" rel="noopener noreferrer" style={{textDecorationLine: "none"}}>
                    <img
                        alt="Logo VUR"
                        src={require("assets/img/notarias/vur.png")}
                    ></img>
                  </a>
                </Col>
                <Col Col md="2" sm="2">
                  <a href="https://www.registraduria.gov.co/" target="_blank" rel="noopener noreferrer" style={{textDecorationLine: "none"}}>
                    <img
                        alt="Logo Registraduria Nacional del Estado Civil"
                        src={require("assets/img/notarias/registraduria.png")}
                    ></img>
                  </a>
                </Col>
              </Row>
            </CardBody>
          </Card>
          <Card>
            <b>Directorio de agremiaciones, asociaciones y otros grupos de interés</b>
            <CardBody>
              <Row>
                <Col Col md="2" sm="2">
                  <a href="https://www.gobiernoenlinea.gov.co/" target="_blank" rel="noopener noreferrer"  style={{textDecorationLine: "none"}}>
                    <img
                      alt="Logo del Gobierno de Colombia"
                      src={require("assets/img/notarias/gobierno.png")}
                    ></img>
                  </a>
                </Col>
                <Col Col md="2" sm="2" >
                  <center>
                  <a href="https://www.ucnc.com.co/sitio/" target="_blank" rel="noopener noreferrer" style={{textDecorationLine: "none"}}>
                    <img
                      alt="Logo de la Unión Colegiada del Notariado Colombiano"
                      src={require("assets/img/notarias/notario.jpg")}
                    ></img>
                    </a>
                  </center>
                </Col>
                <Col Col md="2" sm="2">
                <a href="https://www.caauinl.com/" target="_blank" rel="noopener noreferrer" style={{textDecorationLine: "none"}}>
                  <img
                    style={{textDecorationLine: "none"}}
                    alt="Logo de la Comisión de Asuntos Americanos"
                    src={require("assets/img/notarias/un.png")}
                  ></img>
                  </a>
                </Col>
              </Row>
            </CardBody>
          </Card>
          <br></br>
        </Container>
      </div>
    </>
  );
}

export default Contacto;
